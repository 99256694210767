import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    devicesInfoList: []
  },
  mutations: {
    SET_DEVICES_INFO_LIST: (state, params) => {
      state.devicesInfoList.push(params)
    }
  },
  actions: {
  },
  modules: {
  }
})
