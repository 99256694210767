<template>
  <div class="FindResult">
    <div class="title">
      <span>已经发现</span>
      <span style="color: #409EFF;"> {{ devicesInfoList.length }} </span>
      <span>台赛凡智云，请选择您需要连接的设备：</span>
    </div>
    <!-- <swiper ref="mySwiper" :options="swiperOptions" class="swiper">
      <swiper-slide v-for="(v, i) in devicesInfoList" :key="i" class="swiper-no-swiping">
        <Device :info="v" />
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
      <div class="swiper-button-prev" slot="button-prev" />
      <div class="swiper-button-next" slot="button-next" />
    </swiper> -->
    <el-carousel trigger="click" arrow="always" :autoplay="false" class="carousel">
      <el-carousel-item v-for="(v, i) in devicesInfoList" :key="i" class="carousel-item">
        <Device :info="v" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import Device from '@/components/Device'

export default {
  components: {
    Device
  },
  data () {
    return {
      // swiperOptions: {
      //   loop: false,
      //   pagination: {
      //     el: '.swiper-pagination',
      //     clickable: true
      //   },
      //   navigation: {
      //     nextEl: '.swiper-button-next',
      //     prevEl: '.swiper-button-prev'
      //   }
      // }
    }
  },
  computed: {
    devicesInfoList () {
      console.log()
      return this.$store.state.devicesInfoList
    }
  }
}
</script>

<style lang="scss" scoped>
.FindResult {
  width: 100%;
  height: 100%;
  .title {
    color: #444;
    font-size: 14px;
    font-weight: bolder;
    width: 100%;
    height: 30px;
    line-height: 34px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  .carousel {
    width: 100%;
    max-width: 980px;
    height: calc(100% - 100px);
    .carousel-item {
      width: 100%;
      height: 100%;
    }
  }
  .swiper {
    width: 100%;
    max-width: 980px;
    height: calc(100% - 100px);
  }
}
::v-deep .el-carousel__container {
  height: 100%;
}
::v-deep .el-carousel__button {
  background-color: #999;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}
</style>
