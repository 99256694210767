<template>
  <div class="Find">
    <div class="item item-client">
      <div class="title">方式一：通过下载windows客户端来发现企业云盒</div>
      <img src="@/assets/find.png" class="img-find">
      <div class="label">
        <span>请直接下载</span>
        <a :href="findUrl" target="_blank" style="color:#4086F5;margin: 0 2px;">赛凡智云发现助手</a>
        <span>快速寻找设备</span>
      </div>
    </div>
    <div class="item item-find">
      <div class="title">方式二：通过web浏览器发现企业云盒</div>
      <template v-if="status === 'error'">
        <div class="btns">
          <div class="btn-refresh" @click="clickRefresh">刷新</div>
        </div>
        <div class="label">
          <i class="el-icon-warning"></i>
          <span>使用浏览器在局域网内没有找到</span>
          <span style="font-size: 21px;font-weight: bolder;">企业云盒</span>
        </div>
        <div class="sub-label">
          请检查 企业云盒 的网络连接，确认在同一个局域网内，并点击刷新。
          <br />
          如果仍然无法找到任何设备，请使用方式一进行查找。
        </div>
      </template>
      <template v-else-if="status === 'success'">
        <div class="btns" style="margin: 20px 0 25px;">
          <div class="btn-refresh" @click="clickView">查看</div>
        </div>
        <div class="label">
          <i class="el-icon-success"></i>
          <span>已在局域网内已找到</span>
          <span style="font-size: 21px;font-weight: bolder;">企业云盒</span>
        </div>
      </template>
      <template v-else>
        <div class="loading">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <div class="label">
          <i class="el-icon-loading"></i>
          <span>正在局域网中搜索</span>
          <span style="font-size: 21px;font-weight: bolder;">企业云盒</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: () => {
        return 'init'
      }
    }
  },
  data () {
    return {
      findUrl: 'http://download.saifanbox.com/saifanbox-find.exe'
    }
  },
  methods: {
    // 点击刷新
    clickRefresh () {
      window.location.reload()
    },
    clickView () {
      this.$emit('view')
    }
  }
}
</script>

<style lang="scss" scoped>
 .Find {
  width: 100%;
  height: calc(100% - 65px);
  @include flex-row(space-between);
  margin-top: 15px;
  flex-wrap: wrap;
  .item {
    width: calc(50% - 8px);
    height: 100%;
    border: 1px solid rgb(0 0 0 / 20%);
    border-radius: 4px;
    padding-top: 30px;
    box-sizing: border-box;
    position: relative;
    .title {
      width: 100%;
      color: #333;
      font-size: 14px;
      font-weight: bolder;
      box-sizing: border-box;
      margin-bottom: 10px;
      position: absolute;
      left: 14px;
      top: 16px;
    }
    &.item-client {
      padding-top: 50px;
      box-sizing: border-box;
      @include flex-column();
      .img-find {
        width: auto;
        height: 100px;
        margin-bottom: 76px
      }
      .label {
        font-size: 15px;
      }
    }
    &.item-find {
      @include flex-column();
      .loading {
        width: 130px;
        height: 120px;
        @include flex-row(space-between);
        margin-bottom: 45px;
        span {
          width: 16px;
          height: 80px;
          border-radius: 3px;
          background: lightgreen;
          animation: load 1s ease infinite;
          &:nth-child(2) {
            animation-delay: .2s;
          }
          &:nth-child(3) {
            animation-delay: .4s;
          }
          &:nth-child(4) {
            animation-delay: .6s;
          }
          &:nth-child(5) {
            animation-delay: .8s;
          }
          &:nth-child(6) {
            animation-delay: 1s;
          }
        }
      }
      .btns {
        width: 130px;
        height: 120px;
        @include flex-row();
        .btn-refresh {
          cursor: pointer;
          user-select: none;
          font-weight: 500;
          color: #fff;
          width: 100px;
          width: auto;
          height: 38px;
          padding: 0 24px;
          background-color: $blue;
          border-radius: 20px;
          @include flex-row();
          opacity: 1;
          transition: all .3s;
          &:hover {
            opacity: .9;
          }
        }
      }
      .label {
        font-size: 19px;
        margin: 30px 0 6px;
        .el-icon-loading {
          color: #409EFF;
          font-size: 18px;
          margin-right: 4px;
          margin-left: -26px;
        }
        .el-icon-success {
          color: #67C23A;
          font-size: 18px;
          margin-right: 4px;
          margin-left: -26px;
        }
        .el-icon-warning {
          color: #F56C6C;
          font-size: 18px;
          margin-right: 4px;
          margin-left: -26px;
        }
      }
      .sub-label {
        opacity: .9;
        font-size: 13px;
        line-height: 21px;
      }
    }
  }
}
@keyframes load {
  0%,
  100% {
    transform: scaleY(1);
    background: lightgreen;
  }
  50% {
    transform: scaleY(1.4);
    background: lightblue;
  }
}
</style>
