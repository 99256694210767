<template>
  <div class="wrapper">
    <div class="container">
      <div class="box">
        <div class="logo">
          <img src="@/assets/logo.png" class="img">
          <span class="label">赛凡智云-发现助手</span>
          <div class="help">
            <a :href="helpUrl" target="_blank" style="color:#4086F5;margin: 0 2px;">
              <i class="el-icon-question"></i>
            </a>
          </div>
        </div>
        <Find v-if="findStatus === 'find'" :status="status" @view="clickView" />
        <FindResult v-else />
      </div>
    </div>
    <!-- 分割线 -->
    <div class="footer">
      <div class="slogan">赛凡智云，轻松管理您的数据资产</div>
      <div class="copyright">©2021 Cyphy Technology (Xiamen) Co.,Ltd. 闽ICP备17012438号-3</div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
// import { MessageBox } from 'element-ui'
import Find from '@/components/Find'
import FindResult from '@/components/FindResult'

export default {
  name: 'Home',
  components: {
    Find,
    FindResult
  },
  data () {
    return {
      helpUrl: 'http://saifanbox.com/help?type=foundassistant',
      findStatus: 'find', // find findresult
      status: 'init', // init success error
      tryNumber: 20,
      hasSuccess: false,
      isFailure: 0 // 5
    }
  },
  created () {
    this.$nextTick(() => {
      for (let i = 1; i < (this.tryNumber + 1); i++) {
        if (i === 1) {
          this.getDevicesInfo('qijinas')
        } else {
          this.getDevicesInfo(`qijinas-${i}`)
        }
      }
    })
  },
  methods: {
    // 请求设备详情信息
    async getDevicesInfo (key = 'qijinas') {
      try {
        const res = await axios({
          method: 'GET',
          url: `http://${key}.local:8080/api/v1/initialization/get_info`,
          timeout: 1000 * 3
        })
        if (res?.data?.code === 200) {
          const info = res.data.data
          this.$store.commit('SET_DEVICES_INFO_LIST', info)
          this.toPass()
        } else {
          this.isFailure++
          this.toFailure()
        }
      } catch (error) {
        this.isFailure++
        this.toFailure()
      }
    },
    toFailure () {
      if (this.isFailure === this.tryNumber) {
        this.status = 'error'
      }
    },
    toPass () {
      if (this.hasSuccess) return
      this.hasSuccess = true
      setTimeout(() => {
        this.status = 'success'
        this.$confirm('已发现设备，点击确定跳转到详情页面', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'success',
          closeOnClickModal: false,
          closeOnPressEscape: false
        }).then(() => {
          this.findStatus = 'findresult'
        }).catch(() => {
          // pass
        })
      }, 1666)
    },
    clickView () {
      this.findStatus = 'findresult'
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
  min-height: 700px;
  position: relative;
  .container {
    width: 100%;
    height: 100%;
    min-height: 700px;
    padding-bottom: 70px;
    box-sizing: border-box;
    @include flex-row();
    .box {
      width: 1000px;
      height: 600px;
      padding: 14px 16px 24px;
      box-sizing: border-box;
      background-color: #ffffff;
      box-shadow: 0px 2px 6px 3px #00000020;
      border-radius: 4px;
      position: relative;
      z-index: 99;
      // position: absolute;
      // top: 50%;
      // right: 0;
      // left: 0;
      // margin: 30px auto;
      // transform: scale(1) translateY(-50%);
      .logo {
        width: 100%;
        height: 50px;
        @include flex-row(flex-start);
        position: relative;
        .img {
          width: auto;
          height: 38px;
        }
        .label {
          font-size: 28px;
          font-weight: bolder;
          padding-top: 8px;
          padding-left: 8px;
          text-shadow: 1px 1px 3px #00000080;
          opacity: .9;
        }
        .help {
          position: absolute;
          top: 12px;
          right: 0px;
          i {
            font-size: 20px;
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    position: absolute;
    bottom: 0;
    z-index: 90;
    .slogan {
      color: #fff;
      font-size: 13px;
      width: 100%;
      height: 24px;
      @include flex-row();
    }
    .copyright {
      font-size: 12px;
      color: #fff;
      width: 100%;
      height: 24px;
      box-sizing: border-box;
      @include flex-row();
      margin-bottom: 10px;
    }
  }
}
</style>
