<template>
  <div class="device">
    <img src="@/assets/nas.png" class="nas">
    <div class="info">
      <div class="item">
        <div class="label">设备名称</div>
        <div class="value">{{ info.device_name }}</div>
      </div>
      <div class="item">
        <div class="label">IP地址</div>
        <div class="value">{{ info.ip_addr }}</div>
      </div>
      <div class="item">
        <div class="label">MAC地址</div>
        <div class="value">{{ info.mac_addr }}</div>
      </div>
      <div class="item">
        <div class="label">产品序列号</div>
        <div class="value">{{ info.serial_number }}</div>
      </div>
      <div class="item">
        <div class="label">云盒版本号</div>
        <div class="value">{{ info.version }}</div>
      </div>
      <div class="item">
        <div class="label">产品型号</div>
        <div class="value">{{ info.mode_type }}</div>
      </div>
        <div class="item">
        <div class="label">状态</div>
        <div v-if="info.status === 'initialized'" class="value" style="color: #67C23A;">运行中</div>
        <div v-else class="value" style="color: #E6A23C;">待初始化</div>
      </div>
      <div class="btns">
        <div class="btn" @click="clickConnection">
          <span class="icon"></span>
          <span>{{ info.status === 'initialized' ? '连接' : '初始化' }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
        return {
          device_name: '待定',
          version: '待定',
          ip_addr: '待定',
          mac_addr: '待定',
          mode_type: '待定',
          serial_number: '待定',
          status: '待定' // uninitialized initialized
        }
      }
    }
  },
  methods: {
    clickConnection () {
      const { status, ip_addr: ipaddr } = this.info
      if (status === 'initialized') {
        const url = `http://${ipaddr}`
        window.open(url, '_blank')
      } else {
        const url = `http://${ipaddr}:8888`
        window.open(url, '_blank')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.device {
  width: 100%;
  min-width: 900px;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  @include flex-row(space-around);
  flex-wrap: wrap;
  .nas {
    display: block;
    width: 300px;
    padding-bottom: 40px;
  }
  .info {
    width: 450px;
    .item {
      width: 100%;
      height: 50px;
      padding: 0 14px;
      box-sizing: border-box;
      border-bottom: 1px solid #ebeef5;
      background-color: #ffffff;
      transition: all .3s;
      @include flex-row();
      justify-content: flex-start;
      &:first-child {
        border-top: 1px solid #ebeef5;
      }
      &:nth-child(2n) {
        background-color: #fafafa;
      }
      &:hover {
        background-color: #f5f7fa;
      }
      .label {
        width: 160px;
      }
      .value {
      }
    }
    .btns {
      width: 100%;
      margin-top: 20px;
      @include flex-row(flex-end);
      .btn {
        cursor: pointer;
        user-select: none;
        font-weight: 500;
        color: #fff;
        width: 100px;
        width: auto;
        height: 38px;
        padding: 0 24px;
        background-color: $blue;
        // border-radius: 4px;
        border-radius: 20px;
        @include flex-row();
        opacity: 1;
        transition: all .3s;
        &:hover {
          opacity: .9;
        }
        .icon {
          width: 14px;
          height: 14px;
          margin-right: 6px;
          @include background-image();
          background-image: url($i-link);
        }
      }
    }
  }
}
</style>
